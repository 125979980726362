import type { IconWrapperProps } from '@/core/components/icon/icon-wrapper';
import type { SVGIconProps } from '@/core/components/icon/svg-icon';

import { IconWrapper } from '@/core/components/icon/icon-wrapper';
import SVGIcon from '@/core/components/icon/svg-icon';

export default function CheckMarkIcon(props: SVGIconProps & IconWrapperProps) {
    return (
        <IconWrapper {...props}>
            <SVGIcon
                {...props}
                viewBox={'0 0 130 102'}
            >
                <path
                    d={'M108.1,0L48,60.1L20,32L0,52l48,48l80-80L108.1,0z'}
                    vectorEffect={'non-scaling-stroke'}
                />
            </SVGIcon>
        </IconWrapper>
    );
}

export { CheckMarkIcon };
