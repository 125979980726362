import React from 'react';

export function useDropdownNavigation<DropdownItem>(items: DropdownItem[], onItemSelect: (item: DropdownItem) => void) {
    const [highlightedIndex, setHighlightedIndex] = React.useState(0);
    const highlightedItemRef: React.RefObject<HTMLDivElement> = React.useRef<HTMLDivElement>(null);

    const scrollToHighlightedItem = React.useCallback(() => {
        const item = highlightedItemRef.current;
        if (item) {
            item.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
            });
        }
    }, [highlightedItemRef]);

    const handleEnterKey = React.useCallback(() => {
        const highlightedItem = items[highlightedIndex];
        if (!highlightedItem) {
            return;
        }
        onItemSelect(highlightedItem);
    }, [items, highlightedIndex, onItemSelect]);

    const handleArrowUp = React.useCallback(() => {
        setHighlightedIndex((previousIndex) => {
            if (previousIndex === 0) {
                return items.length - 1;
            }
            return previousIndex - 1;
        });
    }, [items]);

    const handleArrowDown = React.useCallback(() => {
        setHighlightedIndex((previousIndex) => {
            if (previousIndex === items.length - 1) {
                return 0;
            }
            return previousIndex + 1;
        });
    }, [items]);

    const handleKeyDown = React.useCallback(
        (event: KeyboardEvent) => {
            if (event.key === 'ArrowUp') {
                event.preventDefault();
                handleArrowUp();
            } else if (event.key === 'ArrowDown') {
                event.preventDefault();
                handleArrowDown();
            } else if (event.key === ' ') {
                event.preventDefault();
                handleEnterKey();
            }
        },
        [handleArrowUp, handleArrowDown, handleEnterKey],
    );

    const handleMouseClick = React.useCallback(
        (index: number) => {
            setHighlightedIndex(index);
            const selectedItem = items[index];
            if (!selectedItem) {
                return;
            }
            onItemSelect(selectedItem);
        },
        [items, onItemSelect],
    );

    const handleMouseEnter = React.useCallback((index: number) => {
        setHighlightedIndex(index);
    }, []);

    React.useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKeyDown]);

    React.useEffect(() => {
        scrollToHighlightedItem();
    }, [highlightedIndex, scrollToHighlightedItem]);

    return {
        handleMouseClick,
        handleMouseEnter,
        highlightedIndex,
        highlightedItemRef,
    };
}
