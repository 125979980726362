/* eslint-disable indent */
import type { DropdownItem } from '@/core/components/dropdown/dropdown';
import type { FilterGroupV1Option, FilterGroupV1Type } from '@/features/filter/filter-data-v1-type';
import type { MappedFilterGroup } from '@/features/filter/filter-group-desktop-v1/filter-group-desktop-service';
import type { FilterGroupsWithColumns } from '@/features/filter/filter-group-desktop-v1/filter-group-desktop-v1-type';

import React from 'react';

import Divider from '@/core/components/divider/divider';
import SearchInputWithDropdown from '@/core/components/search-input/search-input-with-dropdown/search-input-with-dropdown';
import color from '@/core/features/styles/color';
import { shouldShowDefaultSelected } from '@/features/filter/filter-data/service/filter-data-service';
import {
    createTogglePayloadObject,
    getUpdatedFilterGroupOption,
} from '@/features/filter/filter-data/service/filter-toggle-service';
import FilterGroupShowMore from '@/features/filter/filter-group/filter-group-show-more/filter-group-show-more';
import { getExpandedFilterGroupOptions } from '@/features/filter/filter-group/filter-group-show-more/filter-group-show-more-service';
import FilterGroupSwitch from '@/features/filter/filter-group-desktop-v1/filter-group-desktop/filter-group-switch';
import FilterGroupTitleDesktop from '@/features/filter/filter-group-desktop-v1/filter-group-title-desktop/filter-group-title-desktop';

import styles from './filter-group-desktop.module.scss';

type FilterGroupDesktopProps = {
    filterGroup: MappedFilterGroup;
    isExpanded: boolean;
    isInNestedGroup: boolean;
    isLastGroup: boolean;
    isLoading: boolean;
    isSearchOpenInitial: boolean;
    onDropdownCtaClick: (filterGroup: FilterGroupsWithColumns) => void;
    onFilterSelect: (filterGroup: FilterGroupsWithColumns, option: FilterGroupV1Option) => void;
    onToggleSearch?: (state: boolean) => void;
    qaId?: string;
    toggleExpanded: () => void;
    type: FilterGroupV1Type;
};

export default function FilterGroupDesktop({
    filterGroup,
    isExpanded,
    isInNestedGroup,
    isLastGroup,
    isLoading,
    isSearchOpenInitial,
    onDropdownCtaClick,
    onFilterSelect,
    onToggleSearch,
    qaId,
    toggleExpanded,
    type,
}: FilterGroupDesktopProps) {
    const [filterGroupDropdownState, setFilterGroupDropdownState] = React.useState(filterGroup);

    const filterGroupOptions = getExpandedFilterGroupOptions(filterGroup, isExpanded, false);
    const showDefaultSelected = shouldShowDefaultSelected(filterGroup);

    const onFilterChipClick = (option: FilterGroupV1Option) => {
        onFilterSelect(filterGroup, option);
    };

    const onDropdownItemClick = (item: DropdownItem) => {
        const option = filterGroup.options.find((option) => option.name === item.name);

        if (!option) {
            return;
        }

        setFilterGroupDropdownState((prev) => {
            return {
                ...prev,
                options: getUpdatedFilterGroupOption(prev.options, createTogglePayloadObject(prev, option)),
            };
        });
    };

    React.useEffect(() => {
        setFilterGroupDropdownState(filterGroup);
    }, [filterGroup]);

    return (
        <div data-nosnippet={true}>
            <FilterGroupTitleDesktop
                info={filterGroup.info}
                isInNestedGroup={isInNestedGroup}
                title={filterGroup.title}
            />
            {filterGroupDropdownState.searchField && (
                <div className={styles.searchInputContainer}>
                    <SearchInputWithDropdown
                        ctaText={filterGroupDropdownState.searchField.ctaText}
                        height={40}
                        isDropdownOpenInitial={isSearchOpenInitial}
                        items={filterGroupDropdownState.options}
                        label={filterGroupDropdownState.searchField.placeholder}
                        onCtaClick={() => onDropdownCtaClick(filterGroupDropdownState)}
                        onItemSelect={onDropdownItemClick}
                        onOutsideClick={() => setFilterGroupDropdownState(filterGroup)}
                        onToggleDropdown={onToggleSearch}
                        placeholder={filterGroupDropdownState.searchField.placeholder}
                        searchIconColor={color('nobel')}
                    />
                </div>
            )}
            <FilterGroupSwitch
                columns={filterGroup.columns}
                filterGroupOptions={filterGroupOptions}
                isLoading={isLoading}
                onFilterChipClick={onFilterChipClick}
                qaId={qaId}
                showDefaultSelected={showDefaultSelected}
                type={type}
            />
            {!!filterGroup.maxCount && (
                <FilterGroupShowMore
                    isExpanded={isExpanded}
                    maxCount={filterGroup.maxCount}
                    optionCount={filterGroup.options.length}
                    qaId={'qa-filter-group-show-more-desktop'}
                    toggleExpanded={toggleExpanded}
                />
            )}
            {!isLastGroup && !isInNestedGroup && (
                <Divider
                    className={'width-100'}
                    color={'alto'}
                    marginBottom={15}
                    marginTop={15}
                />
            )}
        </div>
    );
}
