import { CheckMarkIcon } from '@/assets/icons/check-mark';
import { useDropdownNavigation } from '@/core/components/dropdown/use-drop-down-navigation';
import { Button } from '@/core/features';
import color from '@/core/features/styles/color';
import zIndex from '@/core/features/styles/z-index';

import styles from './dropdown.module.scss';

export type DropdownItem = {
    isSelected: boolean;
    name: string;
    title: string;
};

export type DropdownProps = {
    ctaText: null | string;
    items: DropdownItem[];
    onCtaClick: () => void;
    onItemSelect: (item: DropdownItem) => void;
};

export default function Dropdown({ ctaText, items, onCtaClick, onItemSelect }: DropdownProps) {
    const { handleMouseEnter, highlightedIndex, highlightedItemRef } = useDropdownNavigation(items, onItemSelect);

    const hasNoResult = items.length === 0;
    const shouldScroll = items.length > 5;
    const selectedItems = items.filter((item) => item.isSelected);
    const hasSelectedItem = selectedItems.length > 0;
    const showButton = hasSelectedItem && !hasNoResult;

    return (
        <div
            className={styles.dropdown}
            style={{ zIndex: zIndex('dropdown') }}
        >
            <div style={{ height: shouldScroll ? '250px' : 'auto', overflowY: shouldScroll ? 'auto' : 'initial' }}>
                {items.map((item, index) => {
                    const isSelected = item.isSelected;
                    const isHighlighted = index === highlightedIndex;
                    const itemStyle = isSelected
                        ? styles.selectedItem
                        : isHighlighted
                          ? styles.highlightedItem
                          : styles.unselectedItem;
                    const boxStyle = isSelected ? styles.selectedBox : styles.unselectedBox;

                    return (
                        <div
                            className={itemStyle}
                            key={item.title}
                            onClick={() => onItemSelect({ ...item, isSelected: !item.isSelected })}
                            onMouseEnter={() => handleMouseEnter(index)}
                            ref={isHighlighted ? highlightedItemRef : null}
                        >
                            <div className={boxStyle}>
                                {isSelected && (
                                    <CheckMarkIcon
                                        className={styles.icon}
                                        color={color('white')}
                                        height={14}
                                        width={14}
                                    />
                                )}
                            </div>
                            <span>{item.title}</span>
                        </div>
                    );
                })}
                {hasNoResult && (
                    <div className={styles.noResultsText}>Deine Suche ergab leider keine Ergebnisse...</div>
                )}
            </div>
            {ctaText && showButton && (
                <div className={styles.button}>
                    <Button
                        fontSize={14}
                        fontWeight={'bold'}
                        height={40}
                        onClick={onCtaClick}
                        title={ctaText}
                    />
                </div>
            )}
        </div>
    );
}
