import type { SafeOmit } from '@/core/types/safe-omit';
import type { FilterGroupV1, FilterGroupV1Option } from '@/features/filter/filter-data-v1-type';
import type { FilterGroupProps } from '@/features/filter/filter-group/filter-group';
import type { MappedFilterGroup } from '@/features/filter/filter-group-desktop-v1/filter-group-desktop-service';

import { useClientUrl } from '@/core/features/app/app-atoms';
import { savePageScrollPosition } from '@/core/features/scroll/page-scroll-restoration/page-scroll-position-service';
import { scrollToTop } from '@/core/features/scroll/scroll-service';
import { createTogglePayloadObject } from '@/features/filter/filter-data/service/filter-toggle-service';
import { useFilterDataV1Mutation } from '@/features/filter/filter-data/use-filter-data-mutation';
import { useExpandedFilterGroup, useFilterGroupSearchState } from '@/features/filter/filter-group/filter-group-state';
import FilterGroupDesktop from '@/features/filter/filter-group-desktop-v1/filter-group-desktop/filter-group-desktop';

type FilterGroupContainerProps = SafeOmit<FilterGroupProps, 'isExpanded' | 'onFilterSelect' | 'toggleExpanded'> & {
    componentId: string;
    filterGroup: MappedFilterGroup;
    handleFilterChange: () => void;
    isInNestedGroup: boolean;
    isLastGroup: boolean;
    qaId?: string;
};

export default function FilterGroupContainerDesktop(props: FilterGroupContainerProps) {
    const { componentId, filterGroup, handleFilterChange, isLoading, qaId, ...restProps } = props;
    const { toggleFilter, toggleMultipleFiltersAtOnce } = useFilterDataV1Mutation();
    const { isSearchOpenInitial, setSearchOpenInitial } = useFilterGroupSearchState(
        props.componentId,
        filterGroup.name,
    );
    const [currentUrl] = useClientUrl();

    const onFilterSelect = (filterGroup: FilterGroupV1, option: FilterGroupV1Option) => {
        toggleFilter(createTogglePayloadObject(filterGroup, option));
        savePageScrollPosition(currentUrl);
        handleFilterChange();
    };

    const onDropdownCtaClick = (filterGroup: FilterGroupV1) => {
        toggleMultipleFiltersAtOnce(filterGroup, filterGroup.name);
        scrollToTop();
    };

    const { isExpanded, toggleExpandedFilterGroup } = useExpandedFilterGroup(componentId, filterGroup.name);

    return (
        <FilterGroupDesktop
            {...restProps}
            filterGroup={filterGroup}
            isExpanded={isExpanded}
            isLastGroup={props.isLastGroup}
            isLoading={isLoading}
            isSearchOpenInitial={isSearchOpenInitial}
            onDropdownCtaClick={onDropdownCtaClick}
            onFilterSelect={onFilterSelect}
            onToggleSearch={setSearchOpenInitial}
            qaId={qaId}
            toggleExpanded={toggleExpandedFilterGroup}
            type={filterGroup.type}
        />
    );
}
