import { atom, useAtom, useSetAtom } from '@/core/features/store/atom-store';

const expandedFilterGroupsAtom = atom<{ [componentId: string]: string[] }>({});
const filterGroupSearchOpenStatesAtom = atom<Record<string, boolean>>({});
const updateFilterGroupSearchOpenStatesAtom = atom(
    null,
    (get, set, { key, state }: { key: string; state: boolean }) => {
        const prev = get(filterGroupSearchOpenStatesAtom);
        const updatedState = { ...prev, [key]: state };
        set(filterGroupSearchOpenStatesAtom, updatedState);
        return updatedState;
    },
);

//Bing: https://sl.bing.net/ie2hqqCyAq4
export const useExpandedFilterGroup = (componentId: string, filterGroupName: string) => {
    const [expandedFilterGroups, setExpandedFilterGroups] = useAtom(expandedFilterGroupsAtom);
    const toggleExpandedFilterGroup = () => {
        const newExpandedFilterGroups = { ...expandedFilterGroups };
        if (!newExpandedFilterGroups[componentId]) {
            // eslint-disable-next-line fp/no-mutation
            newExpandedFilterGroups[componentId] = [];
        }
        const index = newExpandedFilterGroups[componentId]?.findIndex((name) => name === filterGroupName) ?? -1;
        if (index === -1) {
            newExpandedFilterGroups[componentId]?.push(filterGroupName);
        } else {
            newExpandedFilterGroups[componentId]?.splice(index, 1);
        }
        setExpandedFilterGroups(newExpandedFilterGroups);
    };
    const isExpanded = expandedFilterGroups[componentId]?.some((name) => name === filterGroupName) || false;
    return { isExpanded, toggleExpandedFilterGroup };
};

export const useFilterGroupSearchState = (componentId: string, filterGroupName: string) => {
    const [openFilterGroupSearchStates] = useAtom(filterGroupSearchOpenStatesAtom);
    const setFilterGroupSearchOpenState = useSetAtom(updateFilterGroupSearchOpenStatesAtom);

    const key = `${componentId}_${filterGroupName}`;

    const setSearchOpenInitial = (state: boolean) => {
        setFilterGroupSearchOpenState({ key, state });
    };

    return {
        isSearchOpenInitial: openFilterGroupSearchStates[key] ?? false,
        setSearchOpenInitial,
    };
};
