import type { FilterDataV1Attributes } from '@/features/filter/filter-data-v1-type';

import { atom, useAtom, useAtomValue, useHydrateAtoms } from '@/core/features/store/atom-store';
import {
    getActiveSortOption,
    getFilterOverlayGroups,
    getSelectedFilterCount,
    getSortModalFilterGroup,
    hasNonDefaultFilterOptionsSelected,
    hasResettableFilterOptionsSelected,
} from '@/features/filter/filter-data/service/filter-data-service';

/**
 * State Atoms
 */
const filterDataV1AttributesAtom = atom<FilterDataV1Attributes>({
    groups: [],
    resultCount: 0,
});

/**
 * Derived State Atoms
 */
export const filterGroupsAtom = atom((get) => {
    return get(filterDataV1AttributesAtom).groups;
});
const filterResultCountAtom = atom((get) => get(filterDataV1AttributesAtom).resultCount);
const selectedFilterCountAtom = atom((get) => getSelectedFilterCount(get(filterGroupsAtom)));
const sortModalFilterGroupAtom = atom((get) => getSortModalFilterGroup(get(filterGroupsAtom)));
const activeSortOptionAtom = atom((get) => getActiveSortOption(get(filterGroupsAtom)));
const filterOverlayGroupsAtom = atom((get) => getFilterOverlayGroups(get(filterGroupsAtom)));
const hasNonDefaultFilterOptionsSelectedAtom = atom((get) => hasNonDefaultFilterOptionsSelected(get(filterGroupsAtom)));
const hasResettableFilterOptionsSelectedAtom = atom((get) => hasResettableFilterOptionsSelected(get(filterGroupsAtom)));

/**
 * Hooks
 */
export const useHydrateFilterDataV1Attributes = (attributes: FilterDataV1Attributes) => {
    useHydrateAtoms([[filterDataV1AttributesAtom, attributes]]);
    const [_, setFilterDataV1Attributes] = useFilterDataV1Attributes();

    return setFilterDataV1Attributes;
};

export const useFilterDataV1Attributes = () => useAtom(filterDataV1AttributesAtom);

export const useFilterResultCount = () => useAtomValue(filterResultCountAtom);

export const useSelectedFilterCount = () => useAtomValue(selectedFilterCountAtom);

export const useSortModalFilterGroup = () => useAtomValue(sortModalFilterGroupAtom);

export const useFilterGroups = () => useAtomValue(filterGroupsAtom);

export const useFilterOverlayGroups = () => useAtomValue(filterOverlayGroupsAtom);

export const useActiveSortOption = () => useAtomValue(activeSortOptionAtom);

export const useNonDefaultFilterOptionsSelected = () => useAtomValue(hasNonDefaultFilterOptionsSelectedAtom);

export const useResettableFilterOptionsSelected = () => useAtomValue(hasResettableFilterOptionsSelectedAtom);
