import type { SafeOmit } from '@/core/types/safe-omit';
import type { FilterGroupV1 } from '@/features/filter/filter-data-v1-type';
import type { FilterGroupDesktopType } from '@/features/filter/filter-group-desktop-v1/filter-group-desktop-v1-type';

type MergedFilterGroupType = FilterGroupDesktopType & FilterGroupV1;

export type MappedFilterGroup = SafeOmit<MergedFilterGroupType, 'filterGroups' | 'groups'> & {
    groups: MappedFilterGroup[];
};

export const mapFilterGroupsDesktop = (
    filterGroups: FilterGroupDesktopType[],
    filterDataV1Groups: FilterGroupV1[],
): MappedFilterGroup[] => {
    return filterGroups.reduce<MappedFilterGroup[]>((acc, filterGroup) => {
        const matchedFilterGroup = filterDataV1Groups?.find((group) => group.name === filterGroup.name);

        if (!matchedFilterGroup) {
            return acc;
        }

        const nestedGroups = mapFilterGroupsDesktop(filterGroup.filterGroups ?? [], matchedFilterGroup?.groups);

        return [
            ...acc,
            {
                ...matchedFilterGroup,
                columns: filterGroup.columns,
                groups: nestedGroups,
                maxCount: filterGroup.maxCount ?? 0,
            },
        ];
    }, []);
};
