import type { SafeOmit } from '@/core/types/safe-omit';
import type { PageComponentProps } from '@/features/a-dynamic-page/components/page-component-switch';
import type { FilterGroupDesktopV1Attributes } from '@/features/filter/filter-group-desktop-v1/filter-group-desktop-v1-type';

import React from 'react';

import Divider from '@/core/components/divider/divider';
import { bemModule } from '@/core/utils/bem-classname';
import {
    useFilterGroups,
    useResettableFilterOptionsSelected,
} from '@/features/filter/filter-data/filter-data-attributes-state';
import { useFilterLoading } from '@/features/filter/filter-data/filter-state';
import { FilterActivityGroupDesktop } from '@/features/filter/filter-group-desktop-v1/filter-activity-group-desktop';
import FilterGroupContainerDesktop from '@/features/filter/filter-group-desktop-v1/filter-group-desktop/filter-group-container-desktop';
import { mapFilterGroupsDesktop } from '@/features/filter/filter-group-desktop-v1/filter-group-desktop-service';
import useFilterHandlerDesktop from '@/features/filter/filter-group-desktop-v1/use-filter-handler-desktop';

import styles from './filter-group-desktop-v1.module.scss';

const bem = bemModule(styles);

export type FilterGroupDesktopV1Props = SafeOmit<
    PageComponentProps<FilterGroupDesktopV1Attributes>,
    'componentIndex' | 'componentIri' | 'componentType' | 'pageComponentIndex' | 'pageComponentNames'
> & {
    disableBorders?: boolean;
    marginTop?: number;
    onFilterReset?: () => void;
    padding?: number;
    qaId?: string;
};

export default function FilterGroupDesktopV1({
    attributes,
    componentId,
    disableBorders,
    marginTop,
    onFilterReset,
    padding = 10,
    qaId,
}: FilterGroupDesktopV1Props) {
    const { filterGroups } = attributes;
    const filterDataV1Groups = useFilterGroups();
    const hasResettableOptionsSelected = useResettableFilterOptionsSelected();
    const [isFilterLoading] = useFilterLoading();
    const filterGroupsDesktop = mapFilterGroupsDesktop(filterGroups, filterDataV1Groups);

    const { handleFilterChange, handleFilterReset } = useFilterHandlerDesktop(onFilterReset);

    return (
        <div
            className={bem(styles.filterGroupDesktopV1, { disableBorders })}
            style={{ padding: `0 ${padding}px ${padding}px` }}
        >
            <div className={'flex-start-vertical gap-5'}>
                <div
                    className={'font-size-14 bold'}
                    style={{
                        marginTop: marginTop ? `${marginTop}px` : undefined,
                        paddingTop: marginTop ? undefined : '10px',
                    }}
                >
                    Filter
                </div>
                {hasResettableOptionsSelected && (
                    <div
                        className={'text-left'}
                        onClick={handleFilterReset}
                    >
                        <button className={styles.filterGroupDesktopV1FilterResetButton}>alle zurücksetzen</button>
                    </div>
                )}
                <Divider
                    className={'width-100'}
                    color={'alto'}
                    marginBottom={15}
                    marginTop={10}
                />
            </div>
            {filterGroupsDesktop.map((group, index) => {
                if (group && group.type === 'activity-group' && group.groups !== null && group.groups.length > 0) {
                    return (
                        <FilterActivityGroupDesktop
                            activityGroup={group}
                            componentId={componentId}
                            handleFilterChange={handleFilterChange}
                            isFilterLoading={isFilterLoading}
                            isInitiallyCollapsed={group.isCollapsed}
                            isLastGroup={index === filterGroupsDesktop.length - 1}
                            key={`${group.name}`}
                            qaId={qaId ? qaId : 'qa-activity-filter-group-chip-desktop'}
                        />
                    );
                }
                return (
                    group &&
                    !!group.options.length && (
                        <FilterGroupContainerDesktop
                            componentId={componentId}
                            filterGroup={group}
                            handleFilterChange={handleFilterChange}
                            isInNestedGroup={false}
                            isLastGroup={index === filterGroupsDesktop.length - 1}
                            isLoading={isFilterLoading}
                            key={`${group.title}-${index}`}
                            qaId={qaId ? qaId : 'qa-filter-group-chip-desktop'}
                            showAllOptions={false}
                        />
                    )
                );
            })}
        </div>
    );
}
