import React from 'react';

import { ChevronFullIcon } from '@/assets/icons/chevron-full';
import color from '@/core/features/styles/color';
import { bemModule } from '@/core/utils/bem-classname';

import styles from './filter-group-show-more.module.scss';
const bem = bemModule(styles);
interface FilterGroupShowMoreProps {
    isExpanded: boolean;
    maxCount: number;
    optionCount: number;
    qaId?: string;
    showAllOptions?: boolean;
    toggleExpanded: () => void;
}

export default function FilterGroupShowMore({
    isExpanded: showMore,
    maxCount,
    optionCount,
    qaId,
    showAllOptions,
    toggleExpanded: setShowMore,
}: FilterGroupShowMoreProps) {
    if (showAllOptions === true || optionCount <= maxCount) {
        return null;
    }
    return (
        <div
            className={bem(styles.showMore)}
            data-qa-id={qaId}
            onClick={setShowMore}
        >
            <span className={styles.showMoreButton}>{!showMore ? 'mehr ' : 'weniger '}anzeigen</span>
            <ChevronFullIcon
                className={!showMore ? styles.arrowDown : styles.arrowUp}
                color={color('endeavour')}
                height={10}
                width={7}
            />
        </div>
    );
}
