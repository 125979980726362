import React from 'react';

import { scrollToTop } from '@/core/features/scroll/scroll-service';
import useIsScrolling from '@/core/features/scroll/use-is-scrolling';
import { atom, useAtom, useSetAtom } from '@/core/features/store/atom-store';
import { useFilterDataV1Mutation } from '@/features/filter/filter-data/use-filter-data-mutation';

const SCROLL_TIMEOUT = 3000;

const timeoutAtom = atom<NodeJS.Timeout | undefined>(undefined);
const hasFilterChangedAtom = atom(false);
const hasScrolledAtom = atom(false);

const scrollTimeoutIdAtom = atom<NodeJS.Timeout | null>(null);

const setScrollTimeoutIdAtom = atom(null, (get, set, timeoutId: NodeJS.Timeout | null) => {
    const currentTimeoutId = get(scrollTimeoutIdAtom);
    if (currentTimeoutId) {
        clearTimeout(currentTimeoutId);
    }
    set(scrollTimeoutIdAtom, timeoutId);
    set(hasScrolledAtom, false);
});

export default function useFilterHandlerDesktop(onFilterReset?: () => void) {
    const isScrolling = useIsScrolling();
    const [hasFilterChanged, setHasFilterChanged] = useAtom(hasFilterChangedAtom);
    const [hasScrolled, setHasScrolled] = useAtom(hasScrolledAtom);
    const [timeoutId] = useAtom(timeoutAtom);

    const setScrollTimeoutId = useSetAtom(setScrollTimeoutIdAtom);
    const { resetFilter } = useFilterDataV1Mutation();

    const handleFilterChange = React.useCallback(() => {
        setHasScrolled(false);

        const newTimeout = setTimeout(() => {
            if (!hasScrolled) {
                scrollToTop();
            }
            setScrollTimeoutId(null);
            setHasFilterChanged(false);
        }, SCROLL_TIMEOUT);

        setScrollTimeoutId(newTimeout);
        setHasFilterChanged(true);
    }, [hasScrolled, setHasFilterChanged, setHasScrolled, setScrollTimeoutId]);

    const handleFilterReset = React.useCallback(() => {
        resetFilter();

        if (onFilterReset) {
            onFilterReset();
            setHasScrolled(false);
            return;
        }

        scrollToTop();
        setHasScrolled(false);
    }, [onFilterReset, resetFilter, setHasScrolled]);

    React.useEffect(() => {
        if (isScrolling && hasFilterChanged) {
            setHasScrolled(true);
            setScrollTimeoutId(null);
        }

        if (timeoutId && hasFilterChanged && hasScrolled) {
            setScrollTimeoutId(null);
            setHasFilterChanged(false);
            setHasScrolled(false);
        }
    }, [
        hasScrolled,
        isScrolling,
        setHasScrolled,
        setHasFilterChanged,
        setScrollTimeoutId,
        timeoutId,
        hasFilterChanged,
    ]);

    return { handleFilterChange, handleFilterReset };
}
