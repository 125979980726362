import type { MappedFilterGroup } from '@/features/filter/filter-group-desktop-v1/filter-group-desktop-service';

import React from 'react';

import { Divider } from '@/core/features';
import FilterCollapsableContainer from '@/features/filter/filter-group/filter-collapseable-container/filter-collapseable-container';
import FilterGroupContainerDesktop from '@/features/filter/filter-group-desktop-v1/filter-group-desktop/filter-group-container-desktop';

export function FilterActivityGroupDesktop({
    activityGroup,
    componentId,
    handleFilterChange,
    isFilterLoading,
    isInitiallyCollapsed,
    isLastGroup,
    qaId,
}: {
    activityGroup: MappedFilterGroup;
    componentId: string;
    handleFilterChange: () => void;
    isFilterLoading: boolean;
    isInitiallyCollapsed: boolean | null;
    isLastGroup: boolean;
    qaId?: string;
}) {
    return (
        <div>
            <FilterCollapsableContainer
                fontSize={'font-size-14'}
                isInitiallyCollapsed={isInitiallyCollapsed}
                qaId={'qa-filter-activity-group-desktop'}
                title={activityGroup.title}
            >
                {activityGroup.groups.map((group, index) => {
                    return (
                        group &&
                        !!group.options.length && (
                            <FilterGroupContainerDesktop
                                componentId={componentId}
                                filterGroup={group}
                                handleFilterChange={handleFilterChange}
                                isInNestedGroup={true}
                                isLastGroup={activityGroup.groups !== null && index === activityGroup.groups.length - 1}
                                isLoading={isFilterLoading}
                                key={`${activityGroup.title}-${group.title}-${index}`}
                                qaId={qaId ? qaId : 'qa-filter-group-chip-desktop'}
                                showAllOptions={false}
                            />
                        )
                    );
                })}
            </FilterCollapsableContainer>
            {!isLastGroup && (
                <Divider
                    className={'width-100'}
                    color={'alto'}
                    marginBottom={15}
                    marginTop={15}
                />
            )}
        </div>
    );
}
