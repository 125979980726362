import type { AtomType } from '@/core/features/store/atom-store';

import { useOverlayHistoryState } from '@/core/features/modals/overlay-history/overlay-history-state';
import useScrollPositionAtom from '@/core/features/scroll/use-scroll-position-atom';
import { atom, useAtom } from '@/core/features/store/atom-store';

type FilterOverlayKey = 'accommodation-filter-overlay' | 'accommodation-filter-overlay-map' | 'filter-data-overlay';

/**
 * State Atoms
 */
const isFilterLoadingAtom = atom(false);
const isFilterFetchingAtom = atom(false);
const isFilterOverlayOpenAtom = atom(false);
const isAccommodationFilterOverlayOpenAtom = atom(false);
const isAccommodationFilterOverlayMapOpenAtom = atom(false);
const filterOverlayScrollPositionAtom = atom<number>(0);

const filterOverlayStateAtoms: Record<FilterOverlayKey, AtomType<boolean>> = {
    'accommodation-filter-overlay': isAccommodationFilterOverlayOpenAtom,
    'accommodation-filter-overlay-map': isAccommodationFilterOverlayMapOpenAtom,
    'filter-data-overlay': isFilterOverlayOpenAtom,
};

/**
 * Hooks
 */
export const useFilterLoading = () => useAtom(isFilterLoadingAtom);
export const useFilterFetching = () => useAtom(isFilterFetchingAtom);

export const useFilterOverlayToggle = ({ overlayKey }: { overlayKey: FilterOverlayKey }) => {
    const filterOverlayStateAtom = filterOverlayStateAtoms[overlayKey];

    if (!filterOverlayStateAtom) {
        throw new Error(`No filter overlay state atom found for key: ${overlayKey}`);
    }

    const { closeOverlay, isOverlayOpen, openOverlay } = useOverlayHistoryState(overlayKey);

    const [_isFilterOverlayOpen, setFilterOverlayOpen] = useAtom(filterOverlayStateAtom);

    const [_filterOverlayScrollPosition, setFilterOverlayScrollPosition] = useAtom(filterOverlayScrollPositionAtom);

    const _openOverlay = () => {
        setFilterOverlayOpen(true);
        openOverlay();
    };

    const _closeOverlay = () => {
        setFilterOverlayOpen(false);
        closeOverlay();
        setFilterOverlayScrollPosition(0);
    };

    return { closeOverlay: _closeOverlay, isOverlayOpen, openOverlay: _openOverlay, overlayKey };
};

export const useFilterOverlayScrollPosition = () => useScrollPositionAtom(filterOverlayScrollPositionAtom);
