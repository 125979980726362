import React from 'react';

import { MagnifyingGlassIcon } from '@/assets/icons/magnifying-glass';
import { WindowCloseIcon } from '@/assets/icons/window-close';
import useOutsideClick from '@/core/features/event-listener/use-outside-click';
import color from '@/core/features/styles/color';

import styles from './search-input.module.scss';

export type SearchInputProps = {
    autoFocus?: boolean;
    height: number;
    inputValue: string;
    label: string;
    onBlur?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
    onFocus?: () => void;
    onInputChange: (value: string) => void;
    onInputClear: () => void;
    placeholder: string;
    searchIconColor: string;
};

export default function SearchInput({
    autoFocus,
    height,
    inputValue,
    label,
    onBlur,
    onFocus,
    onInputChange,
    onInputClear,
    placeholder,
    searchIconColor,
}: SearchInputProps) {
    const [isInputFocused, setInputFocused] = React.useState(false);
    const inputRef = React.useRef<HTMLInputElement>(null);
    const ref = useOutsideClick(() => setInputFocused(false));

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onInputChange(event.target.value);
    };

    const handleInputFocus = () => {
        onFocus?.();
        setInputFocused(true);
        inputRef.current?.focus();
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter' || event.key === 'Escape') {
            onInputClear();
            onBlur?.(event);
            setInputFocused(false);
        }
    };

    const hasInputValue = inputValue.length > 0;

    return (
        <div
            className={`flex-space-between-horizontal ${styles.searchInput} ${
                isInputFocused ? styles.filterSearchInputFocused : ''
            }`}
            ref={ref}
            style={{
                height: `${height}px`,
            }}
        >
            <div
                className={styles.wrapper}
                onClick={handleInputFocus}
            >
                <MagnifyingGlassIcon
                    color={searchIconColor}
                    height={15}
                    width={13}
                />
                <input
                    aria-label={label}
                    autoFocus={autoFocus}
                    className={styles.input}
                    name={'suchen'}
                    onChange={handleInputChange}
                    onFocus={handleInputFocus}
                    onKeyDown={handleKeyDown}
                    placeholder={placeholder}
                    ref={inputRef}
                    type={'text'}
                    value={inputValue}
                />
                {hasInputValue && (
                    <button
                        aria-label={'Eingabe löschen'}
                        className={styles.resetButton}
                        onClick={onInputClear}
                    >
                        <WindowCloseIcon
                            color={color('nobel')}
                            height={20}
                            width={20}
                        />
                    </button>
                )}
            </div>
        </div>
    );
}
