import type { IconWrapperProps } from '@/core/components/icon/icon-wrapper';
import type { SVGIconProps } from '@/core/components/icon/svg-icon';

import { IconWrapper } from '@/core/components/icon/icon-wrapper';
import SVGIcon from '@/core/components/icon/svg-icon';

export default function MagnifyingGlassIcon(props: SVGIconProps & IconWrapperProps) {
    return (
        <IconWrapper {...props}>
            <SVGIcon
                {...props}
                viewBox={'0 0 18 18'}
            >
                <path
                    d={
                        'm17.876 16.484-4.268-4.268a.417.417 0 0 0-.298-.123h-.465A7.311 7.311 0 0 0 7.312 0 7.311 7.311 0 0 0 0 7.312a7.311 7.311 0 0 0 12.093 5.533v.465c0 .112.046.217.123.298l4.268 4.268a.422.422 0 0 0 .598 0l.794-.794a.422.422 0 0 0 0-.598zM7.312 12.937a5.623 5.623 0 0 1-5.625-5.625 5.623 5.623 0 0 1 5.625-5.625 5.623 5.623 0 0 1 5.625 5.625 5.623 5.623 0 0 1-5.625 5.625z'
                    }
                />
            </SVGIcon>
        </IconWrapper>
    );
}

export { MagnifyingGlassIcon };
