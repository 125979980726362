import type { PropsWithChildren } from 'react';

import React from 'react';

import ChevronIcon from '@/assets/icons/chevron';
import color from '@/core/features/styles/color';
import { bemModule } from '@/core/utils/bem-classname';

import styles from './filter-collapsable-container.module.scss';

const bem = bemModule(styles);

type FilterCollapsableContainerProps = PropsWithChildren<{
    fontSize: 'font-size-14' | 'font-size-16';
    isInitiallyCollapsed: boolean | null;
    qaId?: string;
    title: string;
}>;

export default function FilterCollapsableContainer({
    children,
    fontSize,
    isInitiallyCollapsed,
    qaId,
    title,
}: FilterCollapsableContainerProps) {
    const [isCollapsed, setIsCollapsed] = React.useState(isInitiallyCollapsed);

    return (
        <>
            <div
                className={'flex-space-between-horizontal pointer user-select-none'}
                data-qa-id={qaId}
                onClick={() => setIsCollapsed((prevState) => !prevState)}
            >
                <div className={`bold line-height-16 ${fontSize}`}>{title}</div>
                <div className={bem(styles.icon, { open: !isCollapsed })}>
                    <ChevronIcon
                        color={color('nobel')}
                        height={8}
                        width={13}
                    />
                </div>
            </div>
            <div className={bem(styles.collapsableContainer, { open: !isCollapsed })}>
                <div className={'flex-column shrink-0 overflow-hidden'}>{children}</div>
            </div>
        </>
    );
}
