import type { AtomType } from '@/core/features/store/atom-store';

import React from 'react';

import { useAtom } from '@/core/features/store/atom-store';

export default function useScrollPositionAtom(atom: AtomType<number>) {
    const [scrollTop, setScrollPosition] = useAtom(atom);

    const scrollTopRef = React.useRef(scrollTop);

    const onScroll = (top: number) => {
        scrollTopRef.current = top;
    };

    const storeScrollPosition = () => {
        setScrollPosition(scrollTopRef.current);
    };

    return { onScroll, scrollTop, setScrollPosition, storeScrollPosition };
}
