import type { DropdownProps } from '@/core/components/dropdown/dropdown';
import type { SearchInputProps } from '@/core/components/search-input/search-input';
import type { SafeOmit } from '@/core/types/safe-omit';

import React from 'react';

import Dropdown from '@/core/components/dropdown/dropdown';
import SearchInput from '@/core/components/search-input/search-input';
import useOutsideClick from '@/core/features/event-listener/use-outside-click';
import transliterate from '@/core/utils/transliterate';

import styles from './search-input-with-dropdown.module.scss';

export type SearchInputWithDropdownProps = SafeOmit<
    SearchInputProps,
    'inputValue' | 'onBlur' | 'onInputChange' | 'onInputClear'
> &
    DropdownProps & {
        isDropdownOpenInitial: boolean;
        onOutsideClick?: () => void;
        onToggleDropdown?: (state: boolean) => void;
    };

export default function SearchInputWithDropdown(props: SearchInputWithDropdownProps) {
    const [isDropdownOpen, setDropdownOpen] = React.useState(props.isDropdownOpenInitial);
    const [inputValue, setInputValue] = React.useState('');
    const outsideClickRef = useOutsideClick(() => {
        closeDropdown();
        setInputValue('');
        props.onOutsideClick?.();
    });

    const filteredItems = React.useMemo(() => {
        return props.items.filter((item) => {
            const convertedSearchValue = transliterate(inputValue.trim().toLowerCase());
            const convertedItem = transliterate(item.title.trim().toLowerCase());
            return convertedItem.includes(convertedSearchValue);
        });
    }, [props.items, inputValue]);

    const closeDropdown = () => {
        setDropdownOpen(false);
        props.onToggleDropdown?.(false);
    };

    const openDropdown = () => {
        setDropdownOpen(true);
        props.onToggleDropdown?.(true);
    };

    const onFocusWrapper = () => {
        openDropdown();
        props.onFocus?.();
    };

    const onInputChange = (value: string) => {
        setInputValue(value);
    };

    const onInputClear = () => {
        setInputValue('');
    };

    const onCtaClick = () => {
        props.onCtaClick?.();
        closeDropdown();
    };

    const onBlur = () => {
        closeDropdown();
    };

    return (
        <div
            className={styles.searchInputWithDropdown}
            ref={outsideClickRef}
        >
            <SearchInput
                autoFocus={isDropdownOpen}
                height={props.height}
                inputValue={inputValue}
                label={props.label}
                onBlur={onBlur}
                onFocus={onFocusWrapper}
                onInputChange={onInputChange}
                onInputClear={onInputClear}
                placeholder={props.placeholder}
                searchIconColor={props.searchIconColor}
            />

            {isDropdownOpen && (
                <div className={styles.dropdownWrapper}>
                    <Dropdown
                        ctaText={props.ctaText}
                        items={filteredItems}
                        onCtaClick={onCtaClick}
                        onItemSelect={(item) => props.onItemSelect(item)}
                    />
                </div>
            )}
        </div>
    );
}
