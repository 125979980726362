import type { FilterGroupsWithColumns } from '@/features/filter/filter-group-desktop-v1/filter-group-desktop-v1-type';

import { InfoIconTooltip } from '@/core/features';
import { classnames } from '@/core/utils/bem-classname';

interface FilterGroupTitleDesktopProps extends Pick<FilterGroupsWithColumns, 'info' | 'title'> {
    isInNestedGroup: boolean;
}

export default function FilterGroupTitleDesktop({ info, isInNestedGroup, title }: FilterGroupTitleDesktopProps) {
    return (
        <div className={`flex-space-between-horizontal ${isInNestedGroup ? 'margin-top-15' : ''}`}>
            <div className={classnames('line-clamp-2 font-size-14 margin-bottom-15', { bold: !isInNestedGroup })}>
                {title}
            </div>
            {info && (
                <InfoIconTooltip
                    htmlInfoText={info.htmlInfoText}
                    htmlInfoTitle={info.htmlInfoTitle}
                    iconSize={13}
                    tippyConfig={{ placement: 'right' }}
                />
            )}
        </div>
    );
}
