import type { FilterGroupV1Option, FilterGroupV1Type } from '@/features/filter/filter-data-v1-type';

import React from 'react';

import dynamic from 'next/dynamic';

import logger from '@/core/features/logger/logger';

const FilterChipDesktop = dynamic(
    () => import(/* webpackChunkName: "FilterChipDesktop" */ '@/features/filter/filter-chip/filter-chip-desktop'),
);
const FilterButton = dynamic(
    () => import(/* webpackChunkName: "FilterButton" */ '@/features/filter/filter-button/filter-button'),
);

export default function FilterGroupSwitch({
    columns,
    filterGroupOptions,
    isLoading,
    onFilterChipClick,
    qaId,
    showDefaultSelected,
    type,
}: {
    columns: number;
    filterGroupOptions: FilterGroupV1Option[];
    isLoading: boolean;
    onFilterChipClick: (option: FilterGroupV1Option) => void;
    qaId?: string;
    showDefaultSelected: boolean;
    type: FilterGroupV1Type;
}) {
    const classes = { 1: 'flex-column gap-10', 2: 'grid grid-cols-2', 3: 'grid grid-cols-3' };
    switch (columns) {
        case 1:
            return (
                <div className={classes[columns]}>
                    {filterGroupOptions.map((option, index) => (
                        <FilterChipDesktop
                            groupType={type}
                            isLoading={isLoading}
                            key={`${option.name}-${index}`}
                            onClick={onFilterChipClick}
                            option={option}
                            qaId={qaId}
                            showDefaultSelected={showDefaultSelected}
                        />
                    ))}
                </div>
            );
        case 2:
        case 3:
            return (
                <div className={classes[columns]}>
                    {filterGroupOptions.map((option, index) => {
                        return (
                            <FilterButton
                                columns={columns}
                                filterOption={option}
                                index={index}
                                isLoading={isLoading}
                                key={option.name}
                                onFilterChipClick={onFilterChipClick}
                                optionCount={filterGroupOptions.length}
                                showDefaultSelected={showDefaultSelected}
                            />
                        );
                    })}
                </div>
            );
        default: {
            logger.error('FilterGroupSwitch: Unsupported number of columns');
            return null;
        }
    }
}
