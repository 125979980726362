import type { IconWrapperProps } from '@/core/components/icon/icon-wrapper';
import type { SVGIconProps } from '@/core/components/icon/svg-icon';

import { IconWrapper } from '@/core/components/icon/icon-wrapper';
import SVGIcon from '@/core/components/icon/svg-icon';

export default function ChevronIcon(props: SVGIconProps & IconWrapperProps) {
    return (
        <IconWrapper {...props}>
            <SVGIcon
                {...props}
                viewBox={'0 0 182 102'}
            >
                <polygon points={'90,0 0,89.583 10.465,100 90,20.833 169.535,100 180,89.583'} />
            </SVGIcon>
        </IconWrapper>
    );
}
export { ChevronIcon };
