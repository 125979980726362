import type {
    FilterGroupV1,
    FilterGroupV1DisplayType,
    FilterGroupV1Option,
    FilterGroupV1Type,
} from '@/features/filter/filter-data-v1-type';

export const getSelectedFilterCount = (filterGroups?: FilterGroupV1[]) => {
    if (!filterGroups) {
        return 0;
    }
    return filterGroups.reduce((prev, group) => {
        if (isFilterSingleMandatory(group.type)) {
            return prev;
        }
        return prev + group.options.filter((option) => option.isSelected && !option.isDefault).length;
    }, 0);
};

export const getSortModalFilterGroup = (filterGroups: FilterGroupV1[]): FilterGroupV1 | null => {
    return filterGroups.find((group) => group.displayType === 'sortModal' || group.displayType === 'all') ?? null;
};

export const getFilterOverlayGroups = (filterGroups: FilterGroupV1[]): FilterGroupV1[] => {
    return filterGroups.reduce<FilterGroupV1[]>((filteredGroups, group) => {
        if ((group.displayType === 'filterModal' || group.displayType === 'all') && group.options.length !== 0) {
            return [...filteredGroups, group];
        }

        if (group.type === 'activity-group' && group.groups) {
            return [...filteredGroups, { ...group, groups: getFilterOverlayGroups(group.groups) }];
        }

        return filteredGroups;
    }, []);
};

export const getActiveSortOption = (filterGroups: FilterGroupV1[]): FilterGroupV1Option | null => {
    const filterGroup = getSortModalFilterGroup(filterGroups);

    return filterGroup?.options.find((option) => option.isSelected) ?? null;
};

/**
 * @returns true when no option is selected
 */
export const shouldShowDefaultSelected = (filterGroup: FilterGroupV1): boolean => {
    return !filterGroup.options.some((option) => option.isSelected);
};

/**
 * @returns true, when at least one filter option
 *  is selected which is not the default. Sort or single-mandatory filters are excluded from this logic.
 */
export const hasNonDefaultFilterOptionsSelected = (filterGroups: FilterGroupV1[]): boolean => {
    return filterGroups.some((filterGroup) => {
        if (filterGroup.groups && filterGroup.groups.length > 0) {
            return hasNonDefaultFilterOptionsSelected(filterGroup.groups);
        }

        if (isFilterSingleMandatory(filterGroup.type) || isFilterSort(filterGroup.displayType)) {
            return false;
        }
        return filterGroup.options.some((option) => {
            return option.isSelected && !option.isDefault;
        });
    });
};

export const hasResettableFilterOptionsSelected = (filterGroups: FilterGroupV1[]): boolean => {
    return filterGroups.some((filterGroup) => {
        if (filterGroup.groups && filterGroup.groups.length > 0) {
            return hasResettableFilterOptionsSelected(filterGroup.groups);
        }

        if (
            isFilterSingleMandatory(filterGroup.type) ||
            isFilterSort(filterGroup.displayType) ||
            !filterGroup.isResettable
        ) {
            return false;
        }
        return filterGroup.options.some((option) => {
            return option.isSelected && !option.isDefault;
        });
    });
};

export const isFilterSingleMandatory = (type: FilterGroupV1Type) => type === 'single-mandatory';
const isFilterSingle = (type: FilterGroupV1Type) => type === 'single';
export const isFilterRadio = (type: FilterGroupV1Type) => isFilterSingleMandatory(type) || isFilterSingle(type);
export const isFilterSort = (displayType: FilterGroupV1DisplayType) => displayType === 'sortModal';

export const isFilterActive = (
    option: FilterGroupV1Option,
    showDefaultSelected: boolean,
    pendingState: boolean | null,
) => {
    const { isDefault, isSelected } = option;
    if (pendingState !== null) {
        return pendingState;
    }
    if (isDefault && showDefaultSelected) {
        return true;
    }
    return isSelected;
};

export const isFilterSelectable = (
    groupType: FilterGroupV1Type,
    option: FilterGroupV1Option,
    isActive: boolean,
    isLoading: boolean,
) => {
    if (isLoading) {
        return false;
    }

    const { count, isDefault } = option;

    if ((isActive && isDefault) || (!isActive && count === 0)) {
        return false;
    }

    const isSingleMandatory = isFilterSingleMandatory(groupType);
    if (isSingleMandatory && isActive) {
        return false;
    }

    return true;
};
