import type { FilterGroupV1, FilterGroupV1Option } from '@/features/filter/filter-data-v1-type';
import type { FilterGroupsWithColumns } from '@/features/filter/filter-group-desktop-v1/filter-group-desktop-v1-type';

export const getExpandedFilterGroupOptions = (
    filterGroup: FilterGroupV1 | FilterGroupsWithColumns,
    isExpanded: boolean,
    showAllOptions = false,
): FilterGroupV1Option[] => {
    if (showAllOptions || isExpanded || !filterGroup.maxCount) {
        return filterGroup.options;
    }

    return filterGroup.options.slice(0, filterGroup.maxCount);
};
