import type { IconWrapperProps } from '@/core/components/icon/icon-wrapper';
import type { SVGIconProps } from '@/core/components/icon/svg-icon';

import { IconWrapper } from '@/core/components/icon/icon-wrapper';
import SVGIcon from '@/core/components/icon/svg-icon';

export default function ChevronFullIcon(props: SVGIconProps & IconWrapperProps) {
    return (
        <IconWrapper {...props}>
            <SVGIcon
                {...props}
                viewBox={'0 0 7 10'}
            >
                <path
                    d={'m8.115 13.617 4.326-3.825-4.326-3.825 1.332-1.175 5.668 5-5.668 5-1.332-1.175Z'}
                    transform={'translate(-8.115 -4.792)'}
                />
            </SVGIcon>
        </IconWrapper>
    );
}

export { ChevronFullIcon };
